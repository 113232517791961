<template>
  <div id="content-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div
            class="project-page col-sm-12 mt-4 d-flex justify-content-between align-items-center"
            style="text-align: left"
          >
            <h2 class="m-0">Collections</h2>
            <button class="mint-button m-0" v-on:click="goToCreate">
              Create a Collection
            </button>
          </div>
          <p style="margin: 20px">
            Welcome to the Collections Page! As you know, the Hashmasks universe
            consists of 16,384 different portraits that are connected to each
            other in various different ways. Some share the same mask type,
            others are holding toilet paper and 233 of them have a shadow monkey
            sitting on their shoulder.
          </p>

          <p style="margin: 20px">
            This is your opportunity to display your unique collection. Unlike
            other artworks, you can combine your Hashmasks in a way that they
            create their own narrative based on the individual traits (and
            names!). You can give a short description of the collection and vote
            on other collections (does not require gas!).
          </p>
          <div
            v-if="loading"
            class="history-container"
            style="display: flex; justify-content: center"
          >
            <div class="loading-circle"></div>
          </div>
          <div
            v-else
            class="collection-item"
            v-for="item in collections"
            :key="item.id"
          >
            <router-link :to="{ name: 'collection', params: { id: item.id } }">
              <div class="d-flex justify-content-between align-items-center">
                <h4>{{ item.name }}</h4>
                <div class="collections-item-vote">
                  <HeartIcon size="24" fillColor="red" borderColor="black" />
                  <div style="margin-left: 16px">
                    {{ item.vote_count }}
                  </div>
                </div>
              </div>
              <p
                class="sale-section w-100"
                style="
                  font-size: 14px;
                  overflow-wrap: break-word;
                  font-weight: 300;
                "
              >
                {{ item.description }}
              </p>
              <div class="d-flex mt-3">
                <div
                  class="collection-portfolio-item webdesign"
                  v-for="hashmaskItem in item.Masks.slice(0, 6)"
                  :key="hashmaskItem.id"
                >
                  <div class="portfolio-content">
                    <div class="portfolio-img-content">
                      <img v-bind:src="getRevealedMaskUrl(hashmaskItem.id)" />
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.Masks.length > 6"
                  class="collection-item-porfolio-count"
                >
                  <span style="font-weight: 500"
                    >+ {{ item.Masks.length - 6 }}</span
                  >
                </div>
              </div>
            </router-link>
          </div>
          <div v-if="end === false">
            <button
              id="load-more"
              class="mint-button"
              v-on:click="loadMore"
              style="margin-top: 1em; margin-left: 1em"
            >
              Load more
            </button>
          </div>
          <div
            v-if="!loading && collections.length === 0"
            class="history-container"
            style="display: flex; justify-content: center"
          >
            <p>No Collections</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script defer>
import {
  hashmasksBalanceOf,
  getRevealedMaskIndex,
} from '../helpers/contract.js';
import { HASHMASKS_THUMBS_URL } from '../assets/constants';
import { getCollections } from '../helpers/database.worker';
import HeartIcon from '../components/HeartIcon';

export default {
  name: 'Collections',
  components: { HeartIcon },
  data() {
    return {
      items: [],
      loading: false,
      collections: [],
      total: 0,
      limit: 0,
      pageSize: 10,
    };
  },
  async mounted() {
    this.loading = true;
    this.limit += this.pageSize;
    await this.getCollections();
    this.loading = false;
    this.infiniteScroll();
  },
  computed: {
    end() {
      return this.limit >= this.total;
    },
  },
  methods: {
    getRevealedMaskUrl: function(nftIndex) {
      const id = getRevealedMaskIndex(nftIndex);
      return `${HASHMASKS_THUMBS_URL}${id}.png`;
    },
    getCollections: async function() {
      const response = await getCollections(this.limit, this.pageSize);
      this.collections = response.items;
      this.total = response.total;
    },
    infiniteScroll: function() {
      const observer = new IntersectionObserver((entries) => {
        const [loadMoreButton] = entries;
        if (loadMoreButton.isIntersecting) {
          this.limit = this.limit + this.pageSize;
          this.getCollections();
        }
      });
      observer.observe(document.querySelector('#load-more'));
    },
    loadMore: function() {
      this.limit = this.limit + this.pageSize;
      this.getCollections();
    },
    goToCreate: async function() {
      const balance = await hashmasksBalanceOf();
      if (balance < 2) {
        alert('You need to have at least 2 Hashmask to create a collection');
        return;
      }
      this.$router.push({ name: 'collectionCreate' });
    },
  },
};
</script>

<style></style>
