<template>
  <div id="content-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="project-page col-sm-12 mt-0" style="text-align: left">
            <h2>
              {{ collection.name }}
              <span
                v-if="!isEditing && isEditable"
                class="ml-2"
                style="
                  font-size: 16px;
                  text-decoration: underline;
                  cursor: pointer;
                "
                v-on:click="handleEdit"
                >Edit</span
              >
            </h2>
            <!-- Portfolio Wrap -->
            <div class="portfolio-wrap mt-4">
              <div class="ethereum-address-block">
                <router-link
                  :to="{ name: 'owner', params: { id: collection.created_by } }"
                >
                  <span>Owned by:</span> {{ collection.created_by }}
                </router-link>
              </div>
              <br />
              <div class="d-flex justify-content-end">
                <button
                  v-if="isEditing"
                  v-on:click="handleUpdate"
                  class="mint-button m-0"
                >
                  Update
                </button>
              </div>
              <div v-if="isEditing" class="col-sm-12 mt-3">
                <div class="row align-items-center">
                  <h6 class="m-0 col-md-2">Title</h6>
                  <div class="col-md-10">
                    <input
                      type="text"
                      class="w-100 p-2"
                      v-model="collection.name"
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <h6 class="m-0 col-md-2">Description</h6>
                  <div class="col-md-10">
                    <textarea
                      class="w-100 p-2"
                      v-model="collection.description"
                      placeholder="Description"
                    />
                  </div>
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-content-between align-items-center w-100 mt-3"
              >
                <blockquote
                  class="sale-section ml-2 w-100 m-0"
                  style="overflow-wrap: break-word; line-height: 20px"
                >
                  {{ collection.description }}
                </blockquote>
                <div class="collection-item-vote ml-2" v-on:click="handleVote">
                  <div class="d-flex align-items-center">
                    <HeartIcon
                      size="24"
                      :fillColor="collection.isVotedByMe ? `red` : `grey`"
                      :borderColor="collection.isVotedByMe ? `black` : `grey`"
                    />
                    <span class="ml-3">{{ collection.vote_count }}</span>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center mt-5"
              >
                <!-- Portfolio Filter -->
                <div class="filters">
                  <ul id="filters" class="ml-0">
                    <li class="active" data-filter="*" style="font-size: 18px">
                      Hashmasks in the Collection
                      <span class="number-of-masks">{{
                        maskItems.length
                      }}</span>
                    </li>
                  </ul>
                </div>
                <!-- Portfolio Filter -->
              </div>
              <!-- Portfolio Items -->
              <mask-items
                :items="maskItems"
                :isEditing="isEditing"
                :selectedItems="collection.selectedItems"
                :handleSelectMaskItem="handleSelectMaskItem"
              />
              <!-- Portfolio Items Ends -->
            </div>
            <!-- Portfolio Wrap Ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  hashmasksBalanceOf,
  tokenOfOwnerByIndex,
  getNFTName,
  generateSignature,
  isValidETHAddress,
  getEthAddress,
} from '../helpers/contract.js';
import Vue from 'vue';
import {
  getVotedStatus,
  voteCollection,
  unvoteCollection,
  upsertCollection,
  getCollection,
} from '../helpers/database.worker';
import { collectionInfoValidate, generateDataToSign } from '../helpers/utils';
import HeartIcon from '../components/HeartIcon';
import MaskItems from '../components/MaskItems';

export default {
  name: 'Collection',
  components: { HeartIcon, MaskItems },
  data() {
    return {
      maskItems: [],
      isEditable: true,
      isEditing: false,
      collection: {},
    };
  },
  computed: {
    ethAddress() {
      return this.$store.state.defaultAccount;
    },
  },
  async mounted() {
    const collection_id = this.$route.params.id;
    this.collection = await getCollection({ params: { collection_id } });
    if (!this.collection) return;
    const defaultAddress = await getEthAddress();
    this.isEditable = this.collection.created_by === defaultAddress;
    this.collection.selectedItems = this.collection.Masks.map(
      (item) => item.id,
    );
    await this.setVotedStatus();
    await this.setMaskItems();
  },
  methods: {
    getMaskItems: async function() {
      const balance = await hashmasksBalanceOf();
      for (let i = 0; i < balance; i++) {
        if (!this.isEditing) break;
        const index = await tokenOfOwnerByIndex(i);
        const findIndex = this.maskItems.findIndex(
          (item) => item.index.toString() === index.toString(),
        );
        if (findIndex !== -1) continue;
        const name = await getNFTName(index);
        if (this.maskItems.length < balance) {
          this.maskItems.push({ index, name });
        }
      }
      if (!this.isEditing) await this.setMaskItems();
    },
    setVotedStatus: async function() {
      const walletAddresss = await getEthAddress();
      if (!walletAddresss) return;
      const searchParams = {
        params: {
          collection_id: this.collection.id,
          from: walletAddresss,
        },
      };
      this.collection.isVotedByMe = await getVotedStatus(searchParams);
    },
    setMaskItems: async function() {
      this.maskItems = await Promise.all(
        this.collection.selectedItems.map(async (itemIndex) => {
          const name = await getNFTName(itemIndex);
          return { index: itemIndex, name };
        }),
      );
    },
    handleSelectMaskItem: function(selectedItem) {
      if (!this.isEditing) return;
      let selectedItems = this.collection.selectedItems;
      let index = selectedItems.findIndex(
        (item) => item === selectedItem.index,
      );
      if (index === -1) selectedItems = [...selectedItems, selectedItem.index];
      else selectedItems.splice(index, 1);
      this.collection.selectedItems = [...selectedItems];
      this.collection = { ...this.collection };
    },
    handleEdit: function() {
      this.isEditable = false;
      this.isEditing = true;
      this.getMaskItems();
    },
    handleUpdate: async function() {
      const walletAddress = await getEthAddress();
      if (!walletAddress) return;
      if (this.collection.selectedItems.length < 2) {
        alert('Please select at least 2 masks');
        return;
      }
      // Validation
      if (
        !collectionInfoValidate(
          this.collection.name,
          this.collection.description,
        )
      )
        return;
      if (!isValidETHAddress(walletAddress)) {
        alert('Use the valid ETH address');
        return;
      }
      // Generate sign signature
      const signParams = {
        action: 'upsert',
        name: this.collection.name,
        description: this.collection.description,
        is_edit: true,
        from: walletAddress,
        timestamp: Date.now(),
      };
      const dataToSign = generateDataToSign(signParams);
      const signedString = await generateSignature(dataToSign);
      if (!signedString) {
        alert('Connect to the wallet');
        return;
      }
      // Call the backend endpoint
      const params = {
        is_edit: true,
        collection_id: this.collection.id,
        name: this.collection.name,
        description: this.collection.description,
        maskIds: this.collection.selectedItems.map((id) => id.toString()),
        created_by: walletAddress,
        timestamp: signParams.timestamp,
        signature: signedString,
      };

      try {
        const collection = await upsertCollection(params);
        this.collection = { ...this.collection, ...collection };
        await this.setMaskItems();
        this.isEditable = true;
        this.isEditing = false;
        Vue.noty.success('Successfully edited a collection');
        // this.$router.push({name: 'collections'})
      } catch (e) {
        Vue.noty.error('An error has occurred. Please try again');
      }
    },
    handleVote: async function() {
      const walletAddress = await getEthAddress();
      if (!walletAddress) return;
      if (!isValidETHAddress(walletAddress)) {
        alert('Use the valid ETH address');
        return;
      }
      // Check the balance of user
      const balance = await hashmasksBalanceOf();
      if (balance < 1) {
        alert('You need to have at least 1 Hashmask to vote ');
        return;
      }
      // Generate the sign signature
      const signParams = {
        action: this.collection.isVotedByMe ? 'unvote' : 'vote',
        collection_id: this.collection.id,
        from: walletAddress,
        timestamp: Date.now(),
      };
      const dataToSign = generateDataToSign(signParams);
      const signedString = await generateSignature(dataToSign);
      if (!signedString) {
        alert('Connect to the wallet');
        return;
      }
      // Call the backend endpoint
      const params = {
        collection_id: this.collection.id.toString(),
        from: walletAddress,
        timestamp: signParams.timestamp,
        signature: signedString,
      };
      try {
        if (this.collection.isVotedByMe) {
          await unvoteCollection(params);
          this.collection.vote_count--;
          Vue.noty.success('Successfully unvoted a collection');
        } else {
          await voteCollection(params);
          this.collection.vote_count++;
          Vue.noty.success('Successfully voted a collection');
        }
        // this.$router.push({name: 'collections'})
      } catch (e) {
        Vue.noty.error('An error has occurred. Please try again');
      }
      this.collection.isVotedByMe = !this.collection.isVotedByMe;
    },
  },
};
</script>

<style></style>
