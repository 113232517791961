<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    :fill="fillColor"
    :stroke="borderColor"
    stroke-width="1"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
    </path>
  </svg>
</template>

<script>
export default {
  name: "HeartIcon",
  props: {
    size: { required: true, default: "24", type: String },
    fillColor: { required: true, default: "grey",  type: String },
    borderColor: { required: true, default: "grey", type: String },
  },
};
</script>
